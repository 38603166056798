import { ReviewIdEnum } from '@app/core';
import { type } from '@app/store/util';
import { Action } from '@ngrx/store';
import { NotificationInterface, ProductIdEnum } from 'outshared-lib';
import { ProductGradeKeyValue } from './product-grade-key-value.interface';

export const LOAD_PRODUCT_GRADE_BY_REVIEW_ID = type('[productGrade] load by reviewId');
export const LOAD_PRODUCT_GRADE_BY_PRODUCT_ID = type('[productGrade] load by productId');
export const LOAD_PRODUCT_GRADE = type('[productGrade] load');
export const LOAD_PRODUCT_GRADE_SUCCESS = type('[productGrade] load success');
export const LOAD_PRODUCT_GRADE_ERROR = type('[productGrade] load error');
export const RESET = type('[productGrade] reset');

export class LoadProductGradeByProductId implements Action {
    readonly type = LOAD_PRODUCT_GRADE_BY_PRODUCT_ID;

    constructor(public payload: { productId: ProductIdEnum }) {
    }
}

export class LoadProductGradeByReviewId implements Action {
    readonly type = LOAD_PRODUCT_GRADE_BY_REVIEW_ID;

    constructor(public payload: { reviewId: ReviewIdEnum }) {
    }
}

export class LoadProductGrade implements Action {
    readonly type = LOAD_PRODUCT_GRADE;

    constructor(public payload: { reviewId: ReviewIdEnum }) {
    }
}

export class LoadProductGradeSuccess implements Action {
    readonly type = LOAD_PRODUCT_GRADE_SUCCESS;

    constructor(public payload: { productGradeKeyValue: ProductGradeKeyValue }) {
    }
}

export class LoadProductGradeError implements Action {
    readonly type = LOAD_PRODUCT_GRADE_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {
    }
}

export class Reset implements Action {
    readonly type = RESET;
}

export type ProductGradeActions
    = LoadProductGrade
    | LoadProductGradeByReviewId
    | LoadProductGradeByProductId
    | LoadProductGradeSuccess
    | LoadProductGradeError
    | Reset;
